import BaseService from '@/services/api/BaseService';

class Assembly extends BaseService {
  constructor({ $axios }) {
    super({ $axios });
  }

  packed(params) {
    return this.$axios
      .post(`assembly/packed`, params)
      .then((response) => response.data);
  }

  orderPacked(params) {
    return this.$axios
      .post(`assembly/order-packed`, params)
      .then((response) => response.data);
  }

  bulkRecipesPacked(params) {
    return this.$axios
      .post(`assembly/bulk-recipes-packed`, params)
      .then((response) => response.data);
  }
}

export default Assembly;
