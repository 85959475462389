<template>
  <div class="dimmer" :class="{ active }">
    <div class="loader"></div>
    <slot name="custom"></slot>
    <div class="dimmer-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dimmer',
  props: ['active'],
};
</script>

<style>
@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.dimmer {
  position: relative;
}

.dimmer .loader {
  display: none;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.dimmer.active .loader {
  display: block;
}

.dimmer.active .dimmer-content {
  opacity: 0.5;
  pointer-events: none;
}

.loader {
  display: block;
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  color: #467fcf;
}

.loader:before,
.loader:after {
  width: 2.5rem;
  height: 2.5rem;
  margin: -1.25rem 0 0 -1.25rem;
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
}

.loader:before {
  border-radius: 50%;
  border: 3px solid currentColor;
  opacity: 0.15;
}

.loader:after {
  -webkit-animation: loader 0.6s linear;
  animation: loader 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 50%;
  border: 3px solid;
  border-color: transparent;
  border-top-color: currentColor;
  box-shadow: 0 0 0 1px transparent;
}
</style>
