<template>
  <div
    class="form-group"
    :class="[
      { 'input-group': hasIcon },
      { 'has-danger': error },
      { focused: focused },
      { 'has-label': label || $slots.label },
      { 'has-success': valid === true },
      { 'has-danger': valid === false },
    ]"
  >
    <slot name="label">
      <label v-if="label" class="form-control-label" :class="labelClasses">
        {{ label }}
        <span v-if="required">*</span>
      </label>
    </slot>

    <div v-if="addonLeftIcon || $slots.addonLeft" class="input-group-prepend">
      <span class="input-group-text">
        <slot name="addonLeft">
          <i :class="addonLeftIcon"></i>
        </slot>
      </span>
    </div>
    <slot v-bind="slotData">
      <el-select
        class="w-100"
        :class="inputClasses"
        :value="value"
        :popper-append-to-body="false"
        v-bind="$attrs"
        v-on="listeners"
        aria-describedby="addon-right addon-left"
      >
        <el-option-group
          v-for="group in options"
          :key="group.label"
          :label="group.label"
        >
          <el-option
            v-for="item in group.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-option-group>
      </el-select>
    </slot>
    <div v-if="addonRightIcon || $slots.addonRight" class="input-group-append">
      <span class="input-group-text">
        <slot name="addonRight">
          <i :class="addonRightIcon"></i>
        </slot>
      </span>
    </div>
    <slot name="infoBlock"></slot>
    <slot name="helpBlock">
      <div
        v-if="error"
        class="text-danger invalid-feedback px-2"
        style="display: block"
        :class="{ 'mt-2': hasIcon }"
      >
        {{ error }}
      </div>
    </slot>
  </div>
</template>
<script>
export default {
  name: 'base-select-group',
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      description: 'Whether input is required (adds an asterisk *)',
    },
    valid: {
      type: Boolean,
      description: 'Whether is valid',
      default: undefined,
    },
    label: {
      type: String,
      description: 'Input label (text before input)',
    },
    error: {
      type: String,
      description: 'Input error (below input)',
    },
    labelClasses: {
      type: String,
      description: 'Input label css classes',
    },
    inputClasses: {
      type: String,
      description: 'Input css classes',
    },
    value: {
      type: [String, Number],
      description: 'Input value',
    },
    options: {
      type: [Array, Object],
      description: 'Options value',
    },
    addonRightIcon: {
      type: String,
      description: 'Addon right icon',
    },
    addonLeftIcon: {
      type: String,
      description: 'Addon left icon',
    },
  },
  data() {
    return {
      focused: false,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.updateValue,
        focus: this.onFocus,
        blur: this.onBlur,
      };
    },
    slotData() {
      return {
        focused: this.focused,
        ...this.listeners,
      };
    },
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
  },
  methods: {
    updateValue(selected) {
      this.$emit('input', selected);
    },
    onFocus(value) {
      this.focused = true;
      this.$emit('focus', value);
    },
    onBlur(value) {
      this.focused = false;
      this.$emit('blur', value);
    },
  },
};
</script>

<style lang="scss">
.has-danger {
  &:after {
    right: 35px;
  }
  input.el-input__inner {
    border-color: #ec1d24;
  }
}
</style>
