import Resource from './Resource';

class Batches extends Resource {
  constructor({ $axios }) {
    super({ $axios, endpoint: 'batches' });
  }

  find(params, config) {
    return this.$axios
      .get(`${this.endpoint}/find`, Object.assign({}, config, { params }))
      .then((response) => response.data.data);
  }

  start(id, payload) {
    return this.$axios
      .post(`${this.endpoint}/${id}/start`, payload)
      .then((response) => response.data);
  }

  complete(id, payload) {
    return this.$axios
      .post(`${this.endpoint}/${id}/complete`, payload)
      .then((response) => response.data);
  }
}

export default Batches;
