import Resource from './Resource';

class Articles extends Resource {
  constructor({ $axios }) {
    super({ $axios, endpoint: 'articles' });
  }

  findAggregates(params, config) {
    return this.$axios
      .get(`${this.endpoint}/aggregates`, Object.assign({}, config, { params }))
      .then((response) => response.data);
  }

  categoriesForDeliveryDay(params) {
    return this.$axios
      .get(`${this.endpoint}/categories`, { params })
      .then((response) => response.data);
  }
}

export default Articles;
