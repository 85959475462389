import BaseService from './BaseService';

class Auth extends BaseService {
  forgotPassword({ email }) {
    return this.$axios.post('forgot-password', { email });
  }

  // #region ERP API. For Authentication & User Details only.
  erpBaseUrl = process.env.VUE_APP_ERP_API;
  login({ clientId, email, password, rememberMe }) {
    return this.$axios
      .post(
        'api/login',
        {
          clientId,
          email,
          password,
          rememberMe,
        },
        { baseURL: this.erpBaseUrl }
      )
      .then((response) => response.data.data);
  }

  me() {
    return this.$axios
      .get('api/customers/me', { baseURL: this.erpBaseUrl })
      .then((response) => response.data.data);
  }
  // #endregion

  loginPacker({ packerId, password }) {
    return this.$axios
      .post('users/login', { packer_id: packerId, password })
      .then((response) => response.data);
  }

  logout() {
    return this.$axios.post('users/logout').then((response) => response.data);
  }

  mePacker() {
    return this.$axios.get('users/me').then((response) => response.data);
  }

  getDailyPassword() {
    return this.$axios
      .get('users/daily-password')
      .then((response) => response.data);
  }

  resetPassword({ email, password, password2, token }) {
    return this.$axios.post('reset-password', {
      email,
      password,
      password_confirmation: password2,
      token,
    });
  }
}

export default Auth;
