import BaseService from './BaseService';

class Exports extends BaseService {
  constructor({ $axios, endpoint }) {
    super({ $axios });
    this.endpoint = endpoint;
  }

  export(url, params) {
    return this.$axios.get(`${this.endpoint}/${url}`, {
      params,
      responseType: 'blob',
    });
  }
}

export default Exports;
