export default {
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
  },
  namespaced: true,
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0',
  },
};
