export const SET_PAGE_HEADER = 'SET_PAGE_HEADER';
export const SET_PASSWORD_MODAL = 'SET_PASSWORD_MODAL';
export const SET_COLLAPSE_SIDEBAR = 'SET_COLLAPSE_SIDEBAR';

export default {
  actions: {
    setPageHeader({ commit }, value) {
      commit(SET_PAGE_HEADER, value);
    },
    setPasswordModal({ commit }, value) {
      commit(SET_PASSWORD_MODAL, value);
    },
    toggleSideBar({ state, commit }) {
      commit(SET_COLLAPSE_SIDEBAR, !state.collapseSideBar);
    },
  },
  mutations: {
    [SET_PAGE_HEADER](state, value) {
      state.pageHeader = value;
    },
    [SET_PASSWORD_MODAL](state, value) {
      state.passwordModal = value;
    },
    [SET_COLLAPSE_SIDEBAR](state, value) {
      state.collapseSideBar = value;
    },
  },
  namespaced: true,
  state: {
    pageHeader: '',
    passwordModal: false,
    collapseSideBar: false,
  },
};
