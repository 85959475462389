import Resource from './Resource';

class Erp extends Resource {
  constructor({ $axios }) {
    super({ $axios, endpoint: '/erp' });
  }

  getProcessingCount(params, config) {
    return this.$axios
      .get(
        `${this.endpoint}/orders/count-processing`,
        Object.assign({}, config, { params })
      )
      .then((response) => response.data);
  }

  reSyncMissingOrders(params) {
    return this.$axios
      .post(`${this.endpoint}/orders/resync`, params)
      .then((response) => response.data);
  }

  getOrderChangeRequests(params, config) {
    return this.$axios
      .get(
        `${this.endpoint}/order/change-requests`,
        Object.assign({}, config, { params })
      )
      .then((response) => response.data);
  }

  updateOrderChangeRequests(id, payload, config) {
    return this.$axios
      .patch(`${this.endpoint}/order/change-requests/${id}`, payload, config)
      .then((response) => response.data);
  }
}

export default Erp;
