import Permissions from '@/utils/enums/Permissions';
import { checkPermissions, checkRoles } from '@/utils/rolesAndPermissions';
import { mapGetters } from 'vuex';

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        ...mapGetters({
          roles: 'auth/roles',
          permissions: 'auth/permissions',
        }),
      },
    });

    /**
     * Checks whether the current user has permission to perform a specific action or access a particular resource.
     * @function
     * @name Vue.prototype.can
     * @param {string|string[]} value - The permission or array of permissions to check.
     * @returns {boolean} - True if the user has permission, otherwise false.
     */
    Vue.prototype.can = function (value) {
      if (Array.isArray(value)) {
        return value.some((item) => checkPermissions(item, this.permissions));
      } else {
        return checkPermissions(value, this.permissions);
      }
    };

    /**
     * Checks whether the current user has a specific role.
     * @function
     * @name Vue.prototype.is
     * @param {string} value - The role to check.
     * @returns {boolean} - True if the user has the role, otherwise false.
     */
    Vue.prototype.is = function (value) {
      return checkRoles(value, this.roles);
    };

    /**
     * To be used in the template to access the permissions enum.
     */
    Vue.prototype.uiPermissions = Permissions;
  },
};
