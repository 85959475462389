export default [
  {
    label: 'Login types',
    url: 'https://www.loom.com/share/df7310137d1a4b59a459ffd3b839137e',
    routes: ['login', 'assembly-stations'],
  },
  {
    label: 'Admin navigation',
    url: 'https://www.loom.com/share/5b812ab3c4a64da695a07c809c7a5ae5',
    allAdmin: true,
  },
  {
    label: 'Creating a batch',
    url: 'https://www.loom.com/share/b9ca6d8c9eee464b849454d3a9e605c6',
    routes: ['orders', 'sequences'],
  },
  {
    label: 'Starting a batch',
    url: 'https://www.loom.com/share/ab2be38c2fa248e08860c4d00a2ad1de',
    routes: ['orders', 'sequences', 'stations', 'assembly-stations'],
  },
  {
    label: 'Assigning packers to stations',
    url: 'https://www.loom.com/share/456058acdf604356889a4842ec9f7620',
    routes: ['stations', 'articles', 'assembly-stations'],
  },
  {
    label: 'Managing users',
    url: 'https://www.loom.com/share/38d70c59f5034c4f82dc828586b53d60',
    routes: ['users'],
  },
  {
    label: 'Exporting checklists and printing labels',
    url: 'https://www.loom.com/share/fd9285558a43485bb9e9f65f0e5bd663',
    routes: ['exports'],
  },
  {
    label: 'Printing delivery box labels',
    url: 'https://www.loom.com/share/2da5fdf63989431ba41eedfb27543313',
    routes: ['exports'],
  },
  {
    label: 'Assembly password',
    url: 'https://www.loom.com/share/e64c3a58458b4a1d915f5313a6095cc0',
    routes: ['assembly-stations'],
    allAdmin: true,
  },
  {
    label: 'Running stations (Packing recipes)',
    url: 'https://www.loom.com/share/792665e0019445d78fd4fe9e372201f8',
    routes: ['assembly-stations', 'stations'],
  },
  {
    label: 'Live dashboard',
    url: 'https://www.loom.com/share/4aa8077274ad496a9630efcc17f61492',
    routes: ['dashboard'],
  },
  {
    label: 'Can I take a break mid-production?',
    url: 'https://www.loom.com/share/6fd2d146047e476e89afc1af39983746',
    routes: ['assembly-stations'],
  },
  {
    label: 'Assigning ingredients to stations',
    url: 'https://www.loom.com/share/ba94bd06671244f9b95ea86f809acc39',
    routes: ['stations'],
  },
  {
    label: 'Tablets packing routine (How to pack)',
    url: 'https://www.loom.com/share/60edd93bc54f4b18b3430cdd50b40a84',
    routes: ['assembly-stations', 'stations'],
  },
  {
    label: 'Adding, deleting and sorting stations',
    url: 'https://drive.google.com/file/d/1RfoDF9BV3bTZU7gPMqW6Pck31Nb-LI62/view?usp=sharing',
    routes: ['stations'],
  },
];
