import '@/assets/vendor/element-ui/css/table.css';
import '@/assets/vendor/element-ui/css/table-column.css';
import '@/assets/vendor/element-ui/css/select.css';
import '@/assets/vendor/element-ui/css/select-dropdown.css';
import '@/assets/vendor/element-ui/css/option.css';
import '@/assets/vendor/element-ui/css/option-group.css';
import '@/assets/vendor/element-ui/icon.css';
import { Table, TableColumn, Select, Option, OptionGroup } from 'element-ui';

import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';

export default {
  install(Vue) {
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(OptionGroup);
    // configure language
    locale.use(lang);
  },
};
