import Articles from '@/services/api/Articles';
import Assembly from '@/services/api/Assembly';
import Auth from '@/services/api/Auth';
import Batches from '@/services/api/Batches';
import Dashboard from '@/services/api/Dashboard';
import Erp from '@/services/api/Erp';
import Exports from '@/services/api/Exports';
import Resource from '@/services/api/Resource';
import Sequences from '@/services/api/Sequences';
import Stations from '@/services/api/Stations';
import $axios from './axios';

const auth = new Auth({ $axios });
const assembly = new Assembly({ $axios });
const stations = new Stations({ $axios });
const orders = new Resource({ $axios, endpoint: 'orders' });
const sequences = new Sequences({ $axios });
const batches = new Batches({ $axios });
const articles = new Articles({ $axios });
const users = new Resource({ $axios, endpoint: 'users' });
const exports = new Exports({ $axios, endpoint: 'exports' });
const dashboard = new Dashboard({ $axios, endpoint: 'dashboard' });
const erp = new Erp({ $axios, endpoint: 'erp' });

export {
  articles,
  assembly,
  auth,
  batches,
  dashboard,
  erp,
  exports,
  orders,
  sequences,
  stations,
  users,
};
