var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.layoutComponent,{tag:"component"},[_c('template',{slot:"nav"},[_c('div',{staticClass:"nav-wrapper",class:_vm.tabNavWrapperClasses},[_c('ul',{staticClass:"nav",class:[
          _vm.type ? `nav-pills-${_vm.type}` : '',
          _vm.pills ? 'nav-pills' : 'nav-tabs',
          { 'nav-pills-icons': _vm.icons },
          { 'nav-fill': _vm.fill },
          { 'nav-pills-circle': _vm.circle },
          { 'justify-content-center': _vm.centered },
          _vm.tabNavClasses,
        ],attrs:{"role":"tablist"}},_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.id || tab.title,staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",class:{ active: tab.active },attrs:{"data-toggle":"tab","role":"tab","href":`#${tab.id || tab.title}`,"aria-selected":tab.active},on:{"click":function($event){$event.preventDefault();return _vm.activateTab(tab)}}},[_c('tab-item-content',{attrs:{"tab":tab}})],1)])}),0)])]),_c('div',{staticClass:"tab-content",class:[_vm.tabContentClasses],attrs:{"slot":"content"},slot:"content"},[_vm._t("default",null,null,_vm.slotData)],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }