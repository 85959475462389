import { datadogLogs } from '@datadog/browser-logs';
import DDLogger from '@/services/DDLogger';

export default {
  install(Vue) {
    datadogLogs.init({
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      env: process.env.VUE_APP_ENV,
    });

    const ddLogger = new DDLogger(datadogLogs, {
      env: process.env.VUE_APP_ENV,
      project: 'hc-pacman',
    });

    Vue.prototype.$ddLogger = ddLogger;
  },
};
