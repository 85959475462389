import Vue from 'vue';
import Router from 'vue-router';
import { sync } from 'vuex-router-sync';
import store from './store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: 'control/orders',
      component: () => import('./layout/DashboardLayout'),
      children: [
        {
          path: '/control/sequences',
          name: 'sequences',
          component: () => import('./views/control/sequences/Index.vue'),
        },
        {
          path: '/control/orders',
          name: 'orders',
          component: () => import('./views/control/orders/Index.vue'),
        },
        {
          path: '/control/stations',
          name: 'stations',
          component: () => import('./views/control/stations/Index.vue'),
        },
        {
          path: '/control/stations/:id',
          name: 'stations-edit',
          component: () => import('./views/control/stations/Edit.vue'),
        },
        {
          path: '/control/exports',
          name: 'exports',
          component: () => import('./views/control/exports/Index.vue'),
        },
        {
          path: '/control/users',
          name: 'users',
          component: () => import('./views/control/users/Index.vue'),
        },
        {
          path: '/control/users/:id',
          name: 'users-edit',
          component: () => import('./views/control/users/Edit.vue'),
        },
      ],
      meta: {
        requiresAuth: true,
        adminOnly: true,
      },
    },
    {
      path: '/',
      redirect: 'login',
      component: () => import('./layout/AuthLayout'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('./views/Login.vue'),
        },
      ],
    },
    {
      path: '/assembly/stations',
      redirect: 'assembly/stations',
      component: () => import('./layout/AssemblyLayout'),
      children: [
        {
          path: '/assembly/stations',
          name: 'assembly-stations',
          component: () => import('./views/assembly/stations/StationLogin.vue'),
        },
        {
          path: '/assembly/stations/:id',
          name: 'assembly-stations-edit',
          component: () => import('./views/assembly/stations/Edit.vue'),
          meta: {
            requiresAuth: true,
            packerOnly: true,
          },
        },
      ],
    },
    {
      path: '/dashboard',
      redirect: 'dashboard',
      component: () => import('./layout/BlankLayout'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          component: () => import('./views/Dashboard.vue'),
        },
      ],
    },
    {
      path: '/logout',
      beforeEnter: (to, from, next) => {
        store
          .dispatch('auth/logout')
          .then(() => next('/login'))
          .catch(() => next(false));
      },
    },
    {
      path: '/logout-packer',
      beforeEnter: (to, from, next) => {
        store
          .dispatch('auth/logout')
          .then(() => next('/assembly/stations'))
          .catch(() => next(false));
      },
    },
    { path: '/no-access', component: () => import('./layout/PageNoAccess') },
    { path: '*', component: () => import('./layout/PageNotFound') },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters['auth/isAuthenticated']) {
      const query = {};

      if (to.fullPath !== '/') {
        query.redirect = to.fullPath;
      }

      if (to.meta?.packerOnly) {
        next({ path: '/assembly/stations', query: query });
      } else {
        next({ path: '/login', query: query });
      }
    } else {
      if (
        (to.meta.packerOnly && store.state?.auth?.user?.role !== 'packer') ||
        (to.matched.some((record) => record.meta.adminOnly) &&
          !store.state?.auth?.user?.permissions.find((item) =>
            ['controller', 'super_admin'].includes(item.toLowerCase())
          ))
      ) {
        next({ path: '/no-access' });
      }
    }

    next();
  } else {
    next();
  }
});

sync(store, router);

export default router;
