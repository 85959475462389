<template>
  <li class="nav-item">
    <a v-if="link.href" class="nav-link" target="_blank" :href="link.href">
      <template>
        <i :class="link.icon" :title="link.name"></i>
        <span class="nav-link-text">{{ link.name }}</span>
      </template>
    </a>
    <router-link
      v-else
      :to="link.path"
      class="nav-link"
      :target="link.target"
      :href="link.path"
      @click.native="linkClick"
    >
      <template>
        <i :class="link.icon" :title="link.name"></i>
        <span class="nav-link-text">{{ link.name }}</span>
      </template>
    </router-link>
  </li>
</template>

<script>
export default {
  name: 'sidebar-item',
  inject: {
    autoClose: {
      default: true,
    },
  },
  props: {
    link: {
      type: Object,
      default: () => {
        return {
          name: '',
          path: '',
          children: [],
        };
      },
      description:
        'Sidebar link. Can contain name, path, icon and other attributes. See examples for more info',
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
    };
  },
  methods: {
    linkClick() {
      if (
        this.autoClose &&
        this.$sidebar &&
        this.$sidebar.showSidebar === true
      ) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>

<style scoped>
i.ni {
  top: 0;
}
</style>
