<template>
  <div
    :class="`justify-content-${collapseSideBar ? 'center' : 'end'}`"
    class="d-md-flex d-lg-flex toggle-side d-none cursor-pointer"
    @click.prevent="toggleSideBar()"
  >
    <span
      class="fa"
      :class="`fa-caret-square-${collapseSideBar ? 'right' : 'left'}`"
    ></span>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  methods: {
    ...mapActions('control', ['toggleSideBar']),
  },
  computed: {
    ...mapState('control', ['collapseSideBar']),
  },
};
</script>
