import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueHelpers from './plugins/vueHelpers';
import ElementUi from './plugins/element-ui';
import ArgonDashboard from './plugins/argon-dashboard';
import Vuelidate from './plugins/vuelidate';
import LaravelEcho from './plugins/laravel-echo';
import Segment from './plugins/segment';
import RolesAndPermissions from './plugins/rolesAndPermissions';
import PortalVue from 'portal-vue';
import dayjs from 'dayjs';
import store from './store';
import './registerServiceWorker';

import Sentry from './plugins/sentry';
import DatadogLogger from './plugins/datadogLogger';
import './index.scss';

Vue.config.productionTip = false;

Vue.use(ElementUi);
Vue.use(ArgonDashboard);
Vue.use(Vuelidate);
Vue.use(VueHelpers);
Vue.use(PortalVue);
Vue.use(Sentry);
Vue.use(LaravelEcho);
Vue.use(DatadogLogger);
Vue.use(Segment);
Vue.use(RolesAndPermissions);

/**
 * To be used in the template to access the admin url.
 */
Vue.prototype.adminUrl = process.env.VUE_APP_ADMIN_URL;

Object.defineProperties(Vue.prototype, {
  $date: {
    get() {
      return dayjs;
    },
  },
});

store.dispatch('auth/restore').then(() => {
  const app = new Vue({
    render: (h) => h(App),
    router,
    store,
  });

  router.onReady(() => app.$mount('#app'));
});

/**
 * This block of code will require all the js & vue files
 * from the `src` folder.
 *
 * This allows Babel to instrument all the files, and not just
 * the files that are imported or required at runtime.
 *
 * This is needed to calculate true code coverage
 * while running E2E tests with Cypress.
 */
if (process.env.NODE_ENV === 'development') {
  const req = require.context('./', true, /\.(js|vue)$/i);
  req.keys().map((key) => {
    const name = key.match(/\w+/)[0];
    return Vue.component(name, req(key));
  });
}
