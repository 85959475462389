import omitEmptyKeys from '@/utils/omitEmptyKeys';
import BaseService from './BaseService';

class Resource extends BaseService {
  constructor({ $axios, endpoint, entity, plural }) {
    super({ $axios });

    this.endpoint = endpoint || plural || `${entity}s`;
    this.entity = entity;
    this.plural = plural || `${entity}s`;
  }

  deleteById(id, config) {
    return this.$axios.delete(`${this.endpoint}/${id}`, config);
  }

  deleteByParameters(params = {}, config) {
    Object.keys(params).forEach((key) => {
      if (params[key] === true) {
        params[key] = 1;
      } else if (params[key] === false) {
        params[key] = 0;
      }
    });

    return this.$axios
      .delete(
        this.endpoint,
        Object.assign({}, config, { params: omitEmptyKeys(params) })
      )
      .then((response) => response.data);
  }

  export() {
    return this.$axios.get(`${this.endpoint}/export`, { responseType: 'blob' });
  }

  exportByParameters(params = {}, config) {
    Object.keys(params).forEach((key) => {
      if (params[key] === true) {
        params[key] = 1;
      } else if (params[key] === false) {
        params[key] = 0;
      }
    });

    return this.$axios.get(
      `${this.endpoint}/export`,
      Object.assign({}, config, {
        params: omitEmptyKeys(params),
        responseType: 'blob',
      })
    );
  }

  getById(id, config) {
    return this.$axios
      .get(`${this.endpoint}/${id}`, config)
      .then((response) => response.data);
  }

  getByParameters(params = {}, config) {
    Object.keys(params).forEach((key) => {
      if (params[key] === true) {
        params[key] = 1;
      } else if (params[key] === false) {
        params[key] = 0;
      }
    });

    return this.$axios
      .get(
        this.endpoint,
        Object.assign({}, config, { params: omitEmptyKeys(params) })
      )
      .then((response) => response.data);
  }

  save(payload, config) {
    return this.$axios
      .post(this.endpoint, payload, config)
      .then((response) => response.data);
  }

  saveOrUpdate(payload, config) {
    return payload.id
      ? this.updateById(payload.id, payload, config)
      : this.save(payload, config);
  }

  updateById(id, payload, config) {
    return this.$axios
      .patch(`${this.endpoint}/${id}`, payload, config)
      .then((response) => response.data);
  }
}

export default Resource;
