import { AnalyticsBrowser } from '@segment/analytics-next';

// fake segment object for testing
const fakeSegment = () => {
  return {
    track: () => {},
    identify: () => {},
    page: () => {},
  };
};

const loadSegment = () => {
  return AnalyticsBrowser.load({
    writeKey: process.env.VUE_APP_SEGMENT_KEY,
  });
};

export const segment =
  process.env.NODE_ENV === 'test' ? fakeSegment() : loadSegment();
