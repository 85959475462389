import VueEcho from 'vue-echo-laravel';
window.Pusher = require('pusher-js');

export default {
  install(Vue) {
    Vue.use(VueEcho, {
      broadcaster: 'pusher',
      key: process.env.VUE_APP_PUSHER_KEY,
      cluster: 'ap2',
      forceTLS: true,
      disableStats: true,
    });
  },
};
