import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import assembly from './modules/assembly';
import control from './modules/control';
import app from './modules/app';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    assembly,
    control,
    app,
  },
  strict: true,
});
