import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

const IGNORE_LIST = ['Request failed with status code', 'Error: Network Error', 'Network Error', 'Request aborted', 'The object does not support the operation or argument.', 'Request failed with status code 401', 'Request failed with status code 404', 'Request failed with status code 422', 'Production batch did not start yet for this station'];

export default {
  install(Vue) {
    Sentry.init({
      Vue,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.2,
      logErrors: true,
      tracingOptions: {
        trackComponents: true,
      },
      attachProps: true,
      attachStacktrace: true,
      environment: process.env.VUE_APP_ENV,
      ignoreErrors: IGNORE_LIST,
    });
  },
};
