<template>
  <div id="app">
    <notifications></notifications>
    <router-view />
    <help v-if="$route.name !== 'assembly-stations-edit'" />
  </div>
</template>

<script>
import Help from './components/Help';

export default {
  name: 'App',
  components: {
    Help,
  },
};
</script>
