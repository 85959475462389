export const checkPermissions = (value, permissions) => {
  // return false if there are no permissions
  if (!Array.isArray(permissions) || !value) return false;

  // allow all the functionality for 'super_admin' permission
  if (permissions.includes('super_admin')) return true;

  let _return = false;
  if (value.includes('|')) {
    value.split('|').forEach(function (item) {
      if (permissions.includes(item.trim())) {
        _return = true;
      }
    });
  } else if (value.includes('&')) {
    _return = true;
    value.split('&').forEach(function (item) {
      if (!permissions.includes(item.trim())) {
        _return = false;
      }
    });
  } else {
    _return = permissions.includes(value.trim());
  }
  return _return;
};

export const checkRoles = (value, roles) => {
  // return false if there are no roles
  if (!Array.isArray(roles) || !value) return false;

  let _return = false;
  if (value.includes('|')) {
    value.split('|').forEach(function (item) {
      if (roles.includes(item.trim())) {
        _return = true;
      }
    });
  } else if (value.includes('&')) {
    _return = true;
    value.split('&').forEach(function (item) {
      if (!roles.includes(item.trim())) {
        _return = false;
      }
    });
  } else {
    _return = roles.includes(value.trim());
  }
  return _return;
};
