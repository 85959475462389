import BaseService from './BaseService';

class Dashboard extends BaseService {
  constructor({ $axios, endpoint }) {
    super({ $axios });
    this.endpoint = endpoint;
  }

  getStatus() {
    return this.$axios
      .get(`${this.endpoint}/stats`)
      .then((response) => response.data);
  }

  getStations() {
    return this.$axios
      .get(`${this.endpoint}/stations`)
      .then((response) => response.data);
  }
}

export default Dashboard;
