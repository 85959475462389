import Resource from './Resource';

class Stations extends Resource {
  constructor({ $axios }) {
    super({ $axios, endpoint: 'stations' });
  }

  update(id, payload, config) {
    return this.$axios
      .patch(`${this.endpoint}/${id}`, payload, config)
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error.response.data.errors[0]);
      });
  }

  reorder(payload, config) {
    return this.$axios
      .patch(`${this.endpoint}/reorder`, payload, config)
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error.response.data.errors[0]);
      });
  }

  assign(id, payload, config) {
    return this.$axios
      .put(`${this.endpoint}/${id}/articles/assign`, payload, config)
      .then((response) => response.data);
  }

  unassign(id, payload, config) {
    return this.$axios
      .put(`${this.endpoint}/${id}/articles/un-assign`, payload, config)
      .then((response) => response.data);
  }

  start(id) {
    return this.$axios
      .get(`${this.endpoint}/${id}/start`)
      .then((response) => response.data)
      .catch((error) => {
        throw new Error(error.response.data.errors[0]);
      });
  }

  run(id, params, config) {
    return this.$axios
      .get(`${this.endpoint}/${id}/run`, Object.assign({}, config, { params }))
      .then((response) => response.data);
  }

  assignPacker(id, payload, config) {
    return this.$axios
      .put(`${this.endpoint}/${id}/operators/assign-packer`, payload, config)
      .then((response) => response.data);
  }

  unassignPacker(id, payload, config) {
    return this.$axios
      .put(`${this.endpoint}/${id}/operators/un-assign-packer`, payload, config)
      .then((response) => response.data);
  }
}

export default Stations;
