import Resource from './Resource';

class Sequences extends Resource {
  constructor({ $axios }) {
    super({ $axios, endpoint: 'sequences' });
  }

  findSequence(params, config) {
    return this.$axios
      .get(`${this.endpoint}/find`, Object.assign({}, config, { params }))
      .then((response) => response.data);
  }
}

export default Sequences;
