/**
 * Cookie helpers from https://www.tabnine.com/academy/javascript/how-to-set-cookies-javascript/
 * @param cookieName
 */
const getCookie = (cookieName) => {
  const name = cookieName + '=';
  const cDecoded = decodeURIComponent(document.cookie); // sanitize cookie from encoding
  const cArr = cDecoded.split('; ');
  let res;
  cArr.forEach((val) => {
    if (val.indexOf(name) === 0) res = val.substring(name.length);
  });
  return res;
};

/**
 * @param cookieName
 * @param value string cookie value
 * @param ttl integer in seconds
 */
const setCookie = (cookieName, value, ttl, domain) => {
  const expires = new Date(Date.now() + ttl * 1000).toUTCString();
  document.cookie =
    `${cookieName}=${value}; expires=${expires}; path=/` +
    (domain ? `; domain=${domain}` : '');
};

/**
 * @param cookieName
 */
const deleteCookie = (cookieName, domain) => {
  const cookie =
    `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/` +
    (domain ? `; domain=${domain}` : '');
  document.cookie = cookie;
};

export { getCookie, setCookie, deleteCookie };
