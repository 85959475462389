<template>
  <div>
    <button class="btn btn-info custom-style" @click="toggleLinks">
      {{ isShown ? 'Close' : 'Help' }}
    </button>
    <div :class="{ shown: isShown }" class="list-container">
      <h3>Related videos</h3>
      <a
        v-for="(link, index) in linksByRoutes"
        :key="index"
        :href="link.url"
        class="list-link"
        target="_blank"
        rel="noopener"
      >
        <div class="pacman"></div>
        {{ link.label }}
      </a>
    </div>
  </div>
</template>

<script>
import HelpLinks from '../utils/enums/HelpLinks';

export default {
  name: 'Help',
  data() {
    return {
      isShown: false,
      links: HelpLinks.sort(function (a, b) {
        return b.label.localeCompare(a.label);
      }),
    };
  },
  computed: {
    linksByRoutes() {
      const nonAdminRoutes = ['assembly-stations', 'dashboard', 'login'];

      return this.links.filter(
        (link) =>
          link.routes?.includes(this.$route.name) ||
          (link?.allAdmin && !nonAdminRoutes.includes(this.$route.name))
      );
    },
  },
  methods: {
    toggleLinks() {
      this.isShown = !this.isShown;
    },
  },
};
</script>

<style scoped lang="scss">
.custom-style {
  padding: 0;
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  z-index: 100;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

.list-container {
  padding: 24px 0;
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -1000px;
  bottom: 0;
  background-color: #fff;
  box-shadow: 2px 4px 3px rgba(0, 0, 0, 0.8);
  transition: right 0.3s ease-in-out;
  z-index: 99;

  @media screen and (max-width: 700px) {
    width: 300px;
  }

  &.shown {
    right: 0;
  }

  h3 {
    padding-left: 12px;
  }
}

.list-link {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.pacman {
  margin-right: 6px;
  width: 20px;
  height: 20px;
  position: relative;
  background-color: gold;
  border-radius: 50%;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 2px;
    border: 8px solid transparent;
    border-right-color: white;
  }
}
</style>
